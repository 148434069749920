import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\node_modules\\next\\dist\\client\\image-component.js");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\node_modules\\next\\dist\\client\\link.js");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\node_modules\\next\\dist\\client\\script.js");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\src\\components\\content\\blocks\\BannerHome.js");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\src\\components\\content\\blocks\\DestacadosProductImpressions.js");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\src\\components\\global\\EventAnchor.js");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\src\\components\\global\\EventLink.js");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\src\\components\\global\\FooterMarcas.jsx");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\src\\components\\global\\FooterNewsLetterForm.jsx");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\src\\components\\global\\HeaderMIG.js");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\src\\components\\global\\MoscaEtiquetas.js");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\src\\components\\global\\SessionStorage.js");
import(/* webpackMode: "eager" */ "D:\\Soft\\apps\\lp-v5\\src\\components\\offers\\blocks\\Square.js")